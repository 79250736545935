import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  ParamMap,
} from "@angular/router";
import { FilterQueryOp, Predicate } from "breeze-client";
import dayjs from "dayjs";
import { FilterMetadata } from "primeng/api";
import { isObservable, Observable, of } from "rxjs";
import { first, map } from "rxjs/operators";
import invariant from "tiny-invariant";

const LOADING_SCREEN_CLASS = "finished-loading";
const LOADING_SHELL_ID = "loadingscreen";
const UNSUPPORTED_BROWSER_ID = "unsupportedbrowser";
const NONE_DISPLAY_CLASS = "d-none";

export default class Utilities {
  static emptyPromise = new Promise((resolve) => {
    resolve(null);
  });

  static valueAsPromise<T>(value: T): Promise<T> {
    return new Promise<T>((resolve) => resolve(value));
  }

  static showLoadingScreen() {
    const loadingShell = document.getElementById(LOADING_SHELL_ID);
    if (loadingShell && hasClass(loadingShell, LOADING_SCREEN_CLASS)) {
      removeClass(loadingShell, LOADING_SCREEN_CLASS);
    }
  }

  static hideLoadingScreen() {
    const loadingShell = document.getElementById(LOADING_SHELL_ID);
    if (loadingShell && !hasClass(loadingShell, LOADING_SCREEN_CLASS)) {
      addClass(loadingShell, LOADING_SCREEN_CLASS);
    }
  }

  static showUnsupportedBrowserScreen() {
    const unsupportedbrowserDiv = document.getElementById(
      UNSUPPORTED_BROWSER_ID,
    );
    if (
      unsupportedbrowserDiv &&
      hasClass(unsupportedbrowserDiv, NONE_DISPLAY_CLASS)
    ) {
      removeClass(unsupportedbrowserDiv, NONE_DISPLAY_CLASS);
    }
    const loadingShell = document.getElementById(LOADING_SHELL_ID);
    if (loadingShell && !hasClass(loadingShell, NONE_DISPLAY_CLASS)) {
      addClass(loadingShell, NONE_DISPLAY_CLASS);
    }
  }
}

/** returns true if adblock is enabled, false otherwise*/
export function isAdblockEnabled(): boolean {
  // eslint-disable-next-line
  const global = <any>window;

  // eslint-disable-next-line
  if (global.adblock) {
    return true;
  }

  return false;
}

export function hasClass(el: HTMLElement, className: string) {
  if (el.classList) {
    return el.classList.contains(className);
  }

  return !!el.className.match(new RegExp("(\\s|^)" + className + "(\\s|$)"));
}

export function addClass(el: HTMLElement, className: string) {
  if (el.classList) {
    el.classList.add(className);
  } else if (!hasClass(el, className)) {
    el.className += " " + className;
  }
}

export function removeClass(el: HTMLElement, className: string) {
  if (el.classList) {
    el.classList.remove(className);
  } else if (hasClass(el, className)) {
    const reg = new RegExp("(\\s|^)" + className + "(\\s|$)");
    el.className = el.className.replace(reg, " ");
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isEmptyish(item: any) {
  if (item === null || item === undefined || item === "" || item === 0) {
    return true;
  }

  if (Array.isArray(item) && item.length === 0) {
    return true;
  }

  // Empty dates are older than 1970
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (typeof item === "object" && item.getMonth && item.getMonth.call) {
    // eslint-disable-next-line
    if (item.getFullYear() <= 1970) {
      return true;
    }

    return false;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  if (typeof item === "object" && Object.keys(item).length === 0) {
    return true;
  }

  return false;
}

export interface QueryOneOptions {
  sort?: string;
  filter?: Predicate;
  include?: string[]; // Removed for security reasons and is disabled on the backend
  noTracking?: boolean;
  // select?: string[]; // TODO: Removed because accurately typing this is a pain
  skip?: number;
  take: 1;
  top?: number;

  // All HttpClient requests are automatically cancelled when navigating
  // for some cases, we want to persist anyway; if so, set this to true
  __persistOnNavigate?: boolean;
}

export interface QueryOptions {
  sort?: string;
  filter?: Predicate;
  include?: string[]; // Removed for security reasons and is disabled on the backend
  noTracking?: boolean;
  // select?: string[]; // TODO: Removed because accurately typing this is a pain
  skip?: number;
  take?: number;
  top?: number;

  // All HttpClient requests are automatically cancelled when navigating
  // for some cases, we want to persist anyway; if so, set this to true
  __persistOnNavigate?: boolean;
}

export interface IStandardFilter {
  filterQueryOp:
  | FilterQueryOp
  | "in"
  | "notin"
  | "ignore"
  | "inrange"
  | "samedate";
  value?: unknown;
  required?: boolean;
  filterFieldName?: string;
  includeEmpty?: boolean;
}
export interface IStandardFilters {
  [fieldName: string]: IStandardFilter;
}

export interface InlineCountQueryOptions extends QueryOptions {
  inlineCount: boolean;
}

export function instanceOfInlineCountQueryOptions(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  obj: any,
): obj is InlineCountQueryOptions {
  // eslint-disable-next-line
  return obj && "inlineCount" in obj && obj.inlineCount === true;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function instanceOfQueryOneOptions(obj: any): obj is QueryOneOptions {
  // eslint-disable-next-line
  return obj && "take" in obj && obj.take === 1 && !("inlineCount" in obj);
}

type Option<O extends QueryOptions> = O extends InlineCountQueryOptions
  ? InlineCountQueryOptions
  : O extends QueryOneOptions
  ? QueryOneOptions
  : QueryOptions;
export function queryOptions<O extends BreezeOptions = QueryOptions>(
  options?: O,
): Option<O> {
  // eslint-disable-next-line
  return options as any;
}

export type BreezeOptions =
  | QueryOneOptions
  | QueryOptions
  | InlineCountQueryOptions;
export type BreezeResult<O, T> = O extends InlineCountQueryOptions
  ? inlineCountResult<T>
  : O extends QueryOneOptions
  ? T | undefined
  : T[];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FetchMethodFunc<O extends BreezeOptions = QueryOptions, T = any> = (
  queryOptions: O,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchMeta?: any,
) => Promise<BreezeResult<O, T>>;
export class inlineCountResult<T> {
  results: T[];
  inlineCount: number;

  constructor(results: T[], inlineCount: number) {
    this.results = results;
    this.inlineCount = inlineCount;
  }
}

export function GetStandardFiltersPredicate(
  standardFilters: IStandardFilters,
  basePredicate?: Predicate,
): Predicate | undefined {
  const breezeFilterMeta = getBreezeFilterMeta(standardFilters);
  return GetFiltersPredicate(breezeFilterMeta, basePredicate);
}

export function getBreezeFilterMeta(standardFilters: IStandardFilters) {
  const breezeFilterMeta: {
    [s: string]: FilterMetadata;
  } = {};

  if (standardFilters) {
    const filterKeys = Object.keys(standardFilters);
    for (let filterKey of filterKeys) {
      const filterValue = standardFilters[filterKey];
      if (filterValue) {
        const explicitFilterFieldName = filterValue.filterFieldName;
        if (explicitFilterFieldName) {
          filterKey = explicitFilterFieldName;
        }

        // Skip filtering if the value is already empty-ish
        if (!filterValue.includeEmpty && isEmptyish(filterValue.value)) {
          continue;
        }

        // Trim leading spaces from string values
        if (filterValue.value !== null && filterValue.value !== undefined && typeof filterValue.value === 'string') {
          filterValue.value = filterValue.value.trim();
        }

        const filterMetadata: FilterMetadata = {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          value: filterValue.value,
          // eslint-disable-next-line
          matchMode: filterValue.filterQueryOp as any,
        };

        breezeFilterMeta[filterKey] = filterMetadata;
      }
    }
  }

  return breezeFilterMeta;
}

export function augmentFilter<O extends BreezeOptions = QueryOptions>(
  options: O,
  newPredicate: Predicate,
) {
  if (options.filter) {
    options.filter = augmentPredicate(options.filter, newPredicate);
  }

  return options;
}

export function augmentPredicate(
  existingPredicate: Predicate | undefined,
  newPredicate: Predicate | undefined,
) {
  if (!existingPredicate) {
    return newPredicate;
  }

  if (!newPredicate) {
    return existingPredicate;
  }

  return existingPredicate.and(newPredicate);
}

export function GetFiltersPredicate(
  filters: {
    [s: string]: FilterMetadata;
  } | null,
  basePredicate?: Predicate,
): Predicate | undefined {
  let predicate: Predicate | undefined = basePredicate;
  if (filters) {
    const filterKeys = Object.keys(filters);
    for (const filterKey of filterKeys) {
      const filter = filters[filterKey];
      if (filter) {
        // Ignore some match modes, they'll be manually handled elsewhere
        if (filter.matchMode === "ignore") {
          continue;
        }

        let pred: Predicate | undefined;

        if (filter.matchMode === "samedate" && filter.value) {
          filter.matchMode = "inrange";

          const timezoneOffset = new Date().getTimezoneOffset();
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          const startOfDay = dayjs(filter.value).subtract(
            timezoneOffset,
            "minutes",
          );
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          const endOfDay = dayjs(filter.value)
            .subtract(timezoneOffset, "minutes")
            .add(1, "day")
            .subtract(1, "second");
          filter.value = [startOfDay, endOfDay];
        }

        if (filter.matchMode === "inrange" && Array.isArray(filter.value)) {
          // Predicate for after first, before last
          // eslint-disable-next-line
          const [startDate, endDate] = filter.value;
          if (startDate) {
            pred = augmentPredicate(
              pred,
              Predicate.create(
                filterKey,
                FilterQueryOp.GreaterThanOrEqual,
                startDate,
              ),
            );
          }
          if (endDate) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            pred = augmentPredicate(
              pred,
              Predicate.create(
                filterKey,
                FilterQueryOp.LessThanOrEqual,
                // eslint-disable-next-line
                endDate,
              ),
            );
          }
        } else if (
          filter.matchMode === "notin" &&
          Array.isArray(filter.value)
        ) {
          let matchMode = Array.isArray(filter.value) ? "in" : filter.matchMode;

          // Breeze doesn't technically support notin, so instead we use an "in" op, and then just do a not() on it to get the notin that we want.
          const inPred = Predicate.create(filterKey, matchMode, filter.value);
          pred = inPred.not();
        } else {
          let matchMode = Array.isArray(filter.value) ? "in" : filter.matchMode;
          pred = Predicate.create(filterKey, matchMode, filter.value);
        }

        if (pred) {
          predicate = predicate ? predicate.and(pred) : pred;
        }
      }
    }
  }

  return predicate;
}

// https://github.com/developit/dlv
export function dlv(
  // eslint-disable-next-line
  obj: { [x: string]: any },
  // eslint-disable-next-line
  key: any,
  // eslint-disable-next-line
  def: any = undefined,
  // eslint-disable-next-line
  p: any = undefined,
  undef: undefined = undefined,
) {
  if (obj) {
    // eslint-disable-next-line
    if (!obj[key]) {
      // eslint-disable-next-line
      key = key.split ? key.split(".") : key;
      // eslint-disable-next-line
      for (p = 0; p < key.length; p++) {
        // eslint-disable-next-line
        obj = obj ? obj[key[p]] : undef;
      }
    } else {
      // eslint-disable-next-line
      obj = obj[key];
    }
  }

  // eslint-disable-next-line
  return obj === undef ? def : obj;
}

export function getDropdownData<T>(
  data: unknown[],
  nameField: string,
  idField: string | undefined,
  sort: boolean = true,
) {
  let dropdownData: { name: string; id: T }[] = [];

  if (Array.isArray(data)) {
    if (sort) {
      data.sort((a, b) =>
        // eslint-disable-next-line
        (a as any)[nameField].localeCompare((b as any)[nameField]),
      );
    }

    data.forEach((line) => {
      // eslint-disable-next-line
      let value = idField ? (line as any)[idField] : line;
      dropdownData.push({
        // eslint-disable-next-line
        name: (line as any)[nameField] as string,
        id: value as T,
      });
    });
  }

  return dropdownData;
}
// Some day in the future, replace this function with a built-in version:
// https://github.com/tc39/proposal-array-grouping
export const groupBy = <T>(array: T[], predicate: (v: T) => string | number) =>
  array.reduce(
    (acc, value) => {
      (acc[predicate(value)] ||= []).push(value);
      return acc;
    },
    {} as { [key: string]: T[] },
  );

export type RouteParamsObj = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
};

const NUMBER_REGEX = /^[-]?\d+(\.\d+)?$/;

function getRouteParamsFromMap<T extends RouteParamsObj = RouteParamsObj>(
  paramMap: ParamMap,
  shape: { [x: string]: string } | undefined,
): T {
  const paramsObj: RouteParamsObj = {};

  // Get all the parameters
  const keys = paramMap.keys;
  for (const key of keys) {
    let value: unknown = paramMap.get(key);

    // If it's a number, parse it as such
    if (typeof value === "string" && NUMBER_REGEX.test(value)) {
      value = parseFloat(value);
    }

    paramsObj[key] = value;
  }

  // If a shape was provided, go through and make sure they match
  if (shape && typeof shape === "object") {
    const shapedParamsObj: { [x: string]: unknown } = {};
    const shapeKeys = Object.keys(shape);
    for (const shapeKey of shapeKeys) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const rawValue = paramsObj[shapeKey];
      const expectedType = shape[shapeKey];
      if (typeof rawValue === expectedType) {
        shapedParamsObj[shapeKey] = rawValue;
      }
    }

    return shapedParamsObj as T;
  }

  return paramsObj as T;
}

export function getQueryParams<T extends RouteParamsObj = RouteParamsObj>(
  route: ActivatedRoute | ActivatedRouteSnapshot,
  shape: { [x: string]: string } | undefined = undefined,
): Promise<T> {
  if (isObservable(route.queryParamMap)) {
    return route.queryParamMap
      .pipe(
        map((paramMap: ParamMap) => {
          return getRouteParamsFromMap<T>(paramMap, shape);
        }),
      )
      .pipe(first())
      .toPromise()
      .then((x) => {
        invariant(x !== undefined);
        return x;
      });
  } else {
    return of(getRouteParamsFromMap<T>(route.queryParamMap, shape))
      .pipe(first())
      .toPromise()
      .then((x) => {
        invariant(x !== undefined);
        return x;
      });
  }
}

export function getRouteParams<T extends RouteParamsObj = RouteParamsObj>(
  route: ActivatedRoute | ActivatedRouteSnapshot,
  shape: { [x: string]: string } | undefined = undefined,
): Observable<T> {
  if (isObservable(route.paramMap)) {
    return route.paramMap.pipe(
      map((paramMap) => {
        return getRouteParamsFromMap(paramMap, shape);
      }),
    );
  } else {
    return of(getRouteParamsFromMap<T>(route.paramMap, shape));
  }
}

export type ActiveSessionLevel = "global"; //| "client-level";

export interface SessionDetails {
  activeSessionLevel: ActiveSessionLevel | null | undefined;
  // clientId: number | null | undefined;

  modelId: number | null | undefined;
  isValidSession: boolean | null | undefined;
  baseSessionUrl: string | null | undefined;

  // urlClientId: number | null | undefined;

  isClientUser?: boolean;
  isBdoUser?: boolean;
  roleNames?: string[];
  roleIds?: number[];
  clientName: string | null | undefined;

  // activeClient: Client | null | undefined;

  tab: string | null | undefined;

  securableName?: string;
  canView?: boolean;
  canCreate?: boolean;
  canEdit?: boolean;
  canDelete?: boolean;
}

export function getBaseSessionUrl(
  typedRouteParams: SessionDetails | undefined,
  level: ActiveSessionLevel,
): string {
  const {
    // clientId
  } = typedRouteParams ?? {};
  let baseSessionUrl = "";

  switch (level) {
    // case "client-level":
    // 	if (clientId === undefined || clientId === null || !(clientId > 0)) {
    // 	} else {
    // 		baseSessionUrl = `/client/${clientId}`;
    // 	}
    // 	break;

    case "global":
    default:
      // continue
      break;
  }

  return baseSessionUrl;
}

export function getContextualUrl(
  path: string,
  sessionDetails: SessionDetails,
  object: { [x: string]: unknown },
) {
  const values: { [x: string]: unknown } = {};

  {
    const keys = Object.keys(sessionDetails || {});
    for (const key of keys) {
      // eslint-disable-next-line
      const value = (sessionDetails as any)[key];
      if (value !== undefined) {
        values[key] = value;
      }
    }
  }

  {
    const keys = Object.keys(object || {});
    for (const key of keys) {
      const value = object[key];
      if (value !== undefined) {
        values[key] = value;
      }
    }
  }

  let contextualUrl = path;

  {
    const keys = Object.keys(values);

    // Sort by length, longest first
    // this way we don't end up replacing a sub-string before the parent
    keys.sort(function (a, b) {
      return b.length - a.length;
    });

    for (const key of keys) {
      const value = values[key];
      contextualUrl = contextualUrl.replace(":" + key, `${value}`);
    }
  }

  return contextualUrl;
}

export function unique<T>(arr: T[]): T[] {
  let u = {},
    a: T[] = [];
  for (let i = 0, l = arr.length; i < l; ++i) {
    const item = arr[i];
    if (item === undefined) {
      continue;
    }

    // eslint-disable-next-line
    if (!u.hasOwnProperty(item as any)) {
      a.push(item);
      // eslint-disable-next-line
      (u as any)[item as any] = 1;
    }
  }
  return a;
}

export function sortJoin<T>(list: T[] | undefined, join: string) {
  const newArr = [...(list ?? [])];
  newArr.sort();
  return newArr.join(join);
}

export function distinctArray<T>(array: T[] | undefined): T[] {
  if (array === undefined) {
    return [];
  }
  let outputArray = Array.from(new Set(array));
  return outputArray;
}
