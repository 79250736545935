import { EntityManager, EntityQuery } from "breeze-client";
import {
  BreezeOptions,
  instanceOfInlineCountQueryOptions,
} from "src/app/services/utilities";
import { UnitOfWorkService } from "src/app/services/unit-of-work/unit-of-work.service";
import { TypedFormGroup, getFormData } from "src/standard/ts/formUtils";
import { StandardDatatableOptions } from "src/standard/standard-datatable-options/StandardDatatableOptions";
import { sortByOrder } from "src/standard/standard-datatable-options/standard-datatable-sort-by-order.pipe";
import { IStandardColumnSettingItem } from "src/standard/standard-datatable-options/columnUtils";
import { ResourceNames } from "src/app/services/bridge/bridge.service";

// TODO: this is harder than it looks
export type OrderByString<TData> = string;

// TODO: this is harder than it looks
export type FieldString<TData> = Extract<keyof TData, string>;

export interface BaseGetManyRequest<TData> {
  pageSize: number;
  pageNumber: number;
  orderBy: OrderByString<TData>;
}

function extractJsonFromQueryString(
  resourceName: string,
  queryString: string,
): string | null {
  const prefix = `${resourceName}?`;
  if (!queryString.startsWith(prefix)) {
    return null;
  }

  const encodedJson = queryString.substring(prefix.length);
  const decodedJson = decodeURIComponent(encodedJson);
  return decodedJson;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function getBreezeQueryString<O extends BreezeOptions>(
  em: EntityManager,
  resource: string,
  options: O,
) {
  const { sort, filter } = options;
  const query = augmentBreezeQuery(new EntityQuery().from(resource), {
    sort,
    filter,
  });

  const url = query._toUri(em);
  const parsed = extractJsonFromQueryString(resource, url);
  return parsed;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function augmentBreezeQuery<O extends BreezeOptions>(
  query: EntityQuery,
  options: O | undefined,
) {
  if (options) {
    const inlineCount = instanceOfInlineCountQueryOptions(options)
      ? options.inlineCount
      : false;

    if (options.filter) {
      query = query.where(options.filter);
    }

    if (options.sort) {
      query = query.orderBy(options.sort);
    } else {
      if (inlineCount && typeof options.take === "number" && options.take > 0) {
        throw Error(
          "Oops! Inline count won't work unless you provide a 'sort' column or take 0.",
        );
      }
    }

    if (Array.isArray(options.include) && options.include.length) {
       query = query.expand(options.include);
    }

    if (inlineCount) {
      query = query.inlineCount(inlineCount);
    }

    if (options.noTracking) {
      query = query.noTracking(options.noTracking);
    }

    // Disabling because the TypeScript to get this correct is hard
    //if (Array.isArray(options.select) && options.select.length) {
    //    query = query.select(options.select);
    //}

    if (typeof options.skip === "number" && options.skip >= 0) {
      query = query.skip(options.skip);
    }

    if (typeof options.take === "number" && options.take >= 0) {
      query = query.take(options.take);
    }

    if (typeof options.top === "number" && options.top >= 0) {
      query = query.top(options.top);
    }
  }

  return query;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function getExportRequest<
  TFormData,
  TListFiltersFormData,
  O extends BreezeOptions,
  TRowData,
>(
  context:
    | {
        formGroup: TypedFormGroup<TFormData>;
        getGetManyRequest: (formData: Partial<TFormData> | undefined) => O;
        standardDatatableOptions:
          | StandardDatatableOptions<TRowData>
          | undefined;
      }
    | {
        formGroup: TypedFormGroup<TFormData>;
        listFiltersFormGroup: TypedFormGroup<TListFiltersFormData>;
        getGetManyRequest: (
          formData: Partial<TFormData> | undefined,
          listFiltersFormGroup: Partial<TListFiltersFormData> | undefined,
        ) => O;
        standardDatatableOptions:
          | StandardDatatableOptions<TRowData>
          | undefined;
      }
    | {
        listFormGroup: TypedFormGroup<TFormData>;
        listFiltersFormGroup: TypedFormGroup<TListFiltersFormData>;
        getGetManyRequest: (
          listFormData: Partial<TFormData> | undefined,
          listFiltersFormGroup: Partial<TListFiltersFormData> | undefined,
        ) => O;
        standardDatatableOptions:
          | StandardDatatableOptions<TRowData>
          | undefined;
      },
  resource: ResourceNames,
  uow: UnitOfWorkService,
) {
  const columnHeaders: string[] = [];

  const columns = context.standardDatatableOptions?.columnSettings
    ? (Object.values(
        context.standardDatatableOptions.columnSettings,
      ) as IStandardColumnSettingItem<TRowData>[])
    : [];
  const sortedColumns = sortByOrder(columns);
  for (let sorted of sortedColumns) {
    if (sorted.unhidable || !sorted.hide) {
      if (sorted.label) {
        columnHeaders.push(sorted.label);
      }
    }
  }

  const formData =
    "formGroup" in context
      ? getFormData(context.formGroup.typedControls)
      : "listFormGroup" in context
      ? getFormData(context.listFormGroup.typedControls)
      : undefined;
  const listFiltersFormData =
    "listFiltersFormGroup" in context
      ? getFormData(context.listFiltersFormGroup.typedControls)
      : undefined;
  if ("listFiltersFormGroup" in context) {
    const queryString = getBreezeQueryString(
      uow.bridge.entityManager,
      resource,
      context.getGetManyRequest(formData, listFiltersFormData),
    );
    return { columnHeaders, queryString };
  } else {
    const queryString = getBreezeQueryString(
      uow.bridge.entityManager,
      resource,
      context.getGetManyRequest(formData),
    );
    return { columnHeaders, queryString };
  }
}
