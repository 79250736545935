import { Pipe, PipeTransform } from "@angular/core";
import dayjs from "dayjs";

const ISO_DATE_FORMAT_REGEX =
  /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5](\d\.)?\d+([+-][0-2]\d:[0-5]\d|Z)$/;
const DATE_ONLY_FORMAT_REGEX = /^\d{4}-[01]\d-[0-3]\d$/;
const DATE_AND_TIME_FORMAT_REGEX = /^\d{4}-[01]\d-[0-3]\d \d{2}:\d{2}:\d{2}$/;
const DATE_AND_TIME_WITH_MILLISECONDS_FORMAT_REGEX =
  /^\d{4}-[01]\d-[0-3]\d \d{2}:\d{2}:\d{2}\.\d{3}$/;
const DATE_AND_TIME_WITH_TIMEZONE_FORMAT_REGEX =
  /^\d{4}-[01]\d-[0-3]\d \d{2}:\d{2}:\d{2}([+-][0-2]\d:[0-5]\d|Z)$/;
const DATE_AND_TIME_WITH_MILLISECONDS_AND_TIMEZONE_FORMAT_REGEX =
  /^\d{4}-[01]\d-[0-3]\d \d{2}:\d{2}:\d{2}\.\d{3}([+-][0-2]\d:[0-5]\d|Z)$/;
const DATE_AND_TIME_WITH_AM_PM_FORMAT_REGEX =
  /^\d{4}-[01]\d-[0-3]\d \d{2}:\d{2}:\d{2} (AM|PM)$/;
const DATE_AND_TIME_WITH_MILLISECONDS_AND_AM_PM_FORMAT_REGEX =
  /^\d{4}-[01]\d-[0-3]\d \d{2}:\d{2}:\d{2}\.\d{3} (AM|PM)$/;
const DATE_AND_TIME_WITH_TIMEZONE_AND_AM_PM_FORMAT_REGEX =
  /^\d{4}-[01]\d-[0-3]\d \d{2}:\d{2}:\d{2} (AM|PM)([+-][0-2]\d:[0-5]\d|Z)$/;
const DATE_AND_TIME_WITH_TIMEZONE_ABBREVIATED_FORMAT_REGEX = /^\d{4}-[01]\d-[0-3]\d \d{2}:\d{2}:\d{2} [A-Z]{3}$/;

const DATE_FORMATS = [
  ISO_DATE_FORMAT_REGEX,
  DATE_ONLY_FORMAT_REGEX,
  DATE_AND_TIME_FORMAT_REGEX,
  DATE_AND_TIME_WITH_MILLISECONDS_FORMAT_REGEX,
  DATE_AND_TIME_WITH_TIMEZONE_FORMAT_REGEX,
  DATE_AND_TIME_WITH_MILLISECONDS_AND_TIMEZONE_FORMAT_REGEX,
  DATE_AND_TIME_WITH_AM_PM_FORMAT_REGEX,
  DATE_AND_TIME_WITH_MILLISECONDS_AND_AM_PM_FORMAT_REGEX,
  DATE_AND_TIME_WITH_TIMEZONE_AND_AM_PM_FORMAT_REGEX,
  DATE_AND_TIME_WITH_TIMEZONE_ABBREVIATED_FORMAT_REGEX,
];

function asLocalFunc(day: Date, asLocal: boolean) {
  const minutesOffset = day.getTimezoneOffset();

  if (!asLocal) {
    return dayjs(day).add(minutesOffset, "minutes").toDate();
  }

  return day;
}

export function convertToDate(
  value: string | number | Date | null | undefined,
  asLocal: boolean = true,
) {
  if (!value) {
    return undefined;
  }

  let asDate: Date | undefined = undefined;
  if (typeof value === "string") {
    let isKnownDateFormat = DATE_FORMATS.some((regex) => regex.test(value));
    if (isKnownDateFormat) {
      asDate = dayjs(value).toDate();
    }
  } else if (typeof value === "number") {
    asDate = new Date(value);
  } else {
    asDate = value;
  }

  if (asDate) {
    const asDayjs = dayjs(asDate);
    if (!asDayjs.isValid()) {
      return undefined;
    }

    return asLocalFunc(asDate, asLocal);
  }

  return undefined;
}

@Pipe({
  standalone: true,
  name: "convertToDate",
})
export class ConvertToDatePipe implements PipeTransform {
  transform(
    value: string | Date | null | undefined,
    asLocal: boolean = true,
  ): Date | undefined {
    return convertToDate(value, asLocal);
  }
}
