import { Routes } from "@angular/router";
import { AppGuard } from "./guards/app-guard/app.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/home",
    pathMatch: "full",
  },
  {
    path: "logout",
    loadChildren: () => import("src/app/logout/routes"),
  },
  {
    path: "maintenance",
    loadComponent: () =>
      import(
        "src/standard/pages/error-pages/maintenance/maintenance.component"
      ),
  },
  {
    path: "access-denied",
    loadComponent: () =>
      import(
        "src/standard/pages/error-pages/access-denied/access-denied.component"
      ),
  },
  {
    path: "",
    canActivate: [AppGuard],
    children: [
      {
        path: "home",
        loadChildren: () => import("./home/routes"),
      },
      {
        path: "engagement",
        loadChildren: () => import("./engagement/routes"),
      },
      {
        path: "workload",
        loadChildren: () => import("./workload/routes"),
      },
      {
        path: "report",
        loadChildren: () => import("./report/routes"),
      },
      {
        path: "my",
        loadChildren: () => import("./my/routes"),
      },
      {
        path: "admin",
        loadChildren: () => import("./admin/routes"),
      },
      {
        path: "design-system",
        loadChildren: () => import("src/standard/pages/design-system/routes"),
      },
      // {
      //   path: "development",
      //   loadChildren: () => import("src/standard/pages/development/routes"),
      // },
      {
        path: "",
        loadChildren: () => import("src/standard/pages/error-pages/routes"),
      },
    ],
  },
];

export default routes;
