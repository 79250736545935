import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from "@angular/common/http";
import { APP_INITIALIZER, ErrorHandler } from "@angular/core";
import { bootstrapApplication } from "@angular/platform-browser";
import { provideAnimations } from "@angular/platform-browser/animations";
import { RouteReuseStrategy, provideRouter } from "@angular/router";
import {
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from "@azure/msal-angular";
import invariant from "tiny-invariant";
import {
  AppComponent,
  init,
  MSALGuardConfigFactory,
  MSALInstanceFactory,
  MSALInterceptorConfigFactory,
} from "./app/app.component";
import routes from "./app/routes";
import { StandardLogService } from "src/standard/logging/logging";
import { BridgeService } from "./app/services/bridge/bridge.service";
import { EntityManagerProviderService } from "./app/services/entity-manager-provider/entity-manager-provider.service";
import { GlobalErrorHandler } from "./app/services/global-error-handler";
import { ServerSideConfigDataService } from "./app/services/server-side-config-data/server-side-config-data.service";
import { UnitOfWorkService } from "./app/services/unit-of-work/unit-of-work.service";
import { ServerSidePublicConfigDataService } from "./app/services/server-side-config-data/server-side-public-config-data.service";

import "@total-typescript/ts-reset";
import { HttpCancelInterceptor } from "./standard/interceptors/http-cancel-interceptor";
import { CustomReuseStrategy } from "./CustomReuseStrategy";

declare global {
  // eslint-disable-next-line no-var
  var __settings:
    | {
        adTenantId: string;
        adClientId: string;
        appInsightsKey: string;
        environment: string;
      }
    | undefined;
}

export function getBaseUrl() {
  const el = document.getElementsByTagName("base")[0];
  invariant(el);

  return el.href;
}

const providers = [
  { provide: "BASE_URL", useFactory: getBaseUrl, deps: [] },
  provideAnimations(),
  BridgeService,
  EntityManagerProviderService,
  ServerSideConfigDataService,
  UnitOfWorkService,
  GlobalErrorHandler,
  {
    provide: ErrorHandler,
    useClass: GlobalErrorHandler,
  },
  {
    provide: APP_INITIALIZER,
    useFactory: init,
    deps: [ServerSidePublicConfigDataService],
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true,
  },
  {
    provide: MSAL_INSTANCE,
    useFactory: MSALInstanceFactory,
  },
  {
    provide: MSAL_GUARD_CONFIG,
    useFactory: MSALGuardConfigFactory,
  },
  {
    provide: MSAL_INTERCEPTOR_CONFIG,
    useFactory: MSALInterceptorConfigFactory,
  },
  MsalService,
  MsalGuard,
  MsalBroadcastService,
  provideHttpClient(
    withInterceptorsFromDi(),
    withInterceptors([HttpCancelInterceptor]),
  ),
  provideRouter(routes),
  { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
  { provide: StandardLogService },
];

void bootstrapApplication(AppComponent, { providers });
