import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { NgIf } from "@angular/common";
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  faBars,
  faCaretLeft,
  faCaretRight,
  faTableCells,
} from "@fortawesome/free-solid-svg-icons";
import { StandardTooltip } from "src/standard/standard-tooltip/standard-tooltip.component";
import { StandardButtonComponent } from "../../standard-button/standard-button.component";
import { StandardIconComponent } from "../../standard-icon/standard-icon.component";
import {
  SM,
  WidthCategory,
  getWidthCategory,
} from "../../standard-nav-shell/standard-nav-shell.component";
import { TooltipPositionType } from "../../standard-tooltip/standard-tooltip.component";
import { IconGridComponent } from "src/app/icons/icon-grid/icon-grid.component";

const arrowWidth = "14px";

@Component({
  standalone: true,
  selector: "standard-nav-side-button-collapser",
  templateUrl: "./standard-nav-side-button-collapser.component.html",
  styles: [
    `
      :host ::ng-deep {
        > standard-button > button {
          height: var(--bs-h-header);
          width: var(--bs-h-header);

          justify-content: center;
          border-radius: 0;

          &:hover {
            background-color: var(--bs-tertiary-bg);
          }
        }
      }

      :host ::ng-deep {
        .fs-arrow {
          font-size: 14px;
        }
      }
    `,
  ],
  animations: [
    trigger("leftArrow", [
      state(
        "void",
        style({
          transform: `translateX(${arrowWidth})`,
        }),
      ),
      state(
        "collapsed",
        style({
          transform: `translateX(${arrowWidth})`,
        }),
      ),
      state(
        "expanded",
        style({
          transform: "translateX(0)",
        }),
      ),
      transition("expanded <=> collapsed", [
        animate(".2s cubic-bezier(0.86, 0, 0.07, 1)"),
      ]),
      transition("void => *", animate(0)),
    ]),
    trigger("rightArrow", [
      state(
        "void",
        style({
          transform: `translateX(-${arrowWidth})`,
        }),
      ),
      state(
        "collapsed",
        style({
          transform: `translateX(-${arrowWidth})`,
        }),
      ),
      state(
        "expanded",
        style({
          transform: "translateX(0)",
        }),
      ),
      transition("expanded <=> collapsed", [
        animate(".2s cubic-bezier(0.86, 0, 0.07, 1)"),
      ]),
      transition("void => *", animate(0)),
    ]),
  ],
  imports: [
    NgIf,

    StandardTooltip,

    StandardButtonComponent,
    StandardIconComponent,

    IconGridComponent,
  ],
})
export class StandardNavSideButtonCollapserComponent
  implements OnInit, OnDestroy
{
  SM = SM;

  arrowWidth = arrowWidth;
  faCaretLeft = faCaretLeft;
  faBars = faBars;
  faCaretRight = faCaretRight;

  constructor(
    private host: ElementRef<HTMLElement>,
    private zone: NgZone,
  ) {}

  @Input() expanded: boolean | undefined;
  @Input() tooltipPosition: TooltipPositionType | undefined;
  @Input() disabled: boolean | undefined;
  @Input() styleClass: string | undefined;

  @Output() onToggle = new EventEmitter<boolean>();

  private observer: ResizeObserver | undefined;
  private isUpdating: boolean = false;
  protected widthCategory: WidthCategory | undefined;

  _wrapperEl: Element | undefined;
  get wrapperEl() {
    if (this.host.nativeElement.parentElement) {
      this._wrapperEl = this.host.nativeElement.parentElement;
    }

    return this._wrapperEl;
  }

  ngOnInit() {
    this.observer = new ResizeObserver((entries) => {
      if (this.isUpdating) {
        return;
      }

      this.zone.run(() => {
        this.isUpdating = true;

        const newWidthCategory = getWidthCategory(
          entries[0]!.contentRect.width,
        );
        this.widthCategory = newWidthCategory;

        requestAnimationFrame(() => {
          this.isUpdating = false;
        });
      });
    });

    this.observer.observe(this.wrapperEl!);
  }

  ngOnDestroy() {
    if (this.wrapperEl) {
      this.observer?.unobserve(this.wrapperEl);
    }
  }
}
