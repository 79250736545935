import { HttpErrorResponse } from "@angular/common/http";
import { DestroyRef, SimpleChange } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { AbstractControl, FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { EntityError } from "breeze-client";
import dayjs from "dayjs";
import { WellKnownErrorCode } from "src/standard/ts/format";
import {
  BreezeQueuedSaveFailedError,
  parseQueuedSaveFailedError,
} from "src/app/services/global-error-handler";
import { KeysOfType } from "src/standard/ts/types";
import { FORMAT_DAYJS_API } from "src/config";
import { MetaEntity } from "src/model/metaEntity";
import { SupportedParamType, tryParseValue } from "./parseUtils";

export type TransitionState =
  | "idle"
  | "submitting"
  | "loading"
  | "silentSubmitting";

export interface TypedFormControl<T> extends Omit<AbstractControl, "value"> {
  value: T | undefined;
}

export type TypedFormControlsFor<T> = {
  [R in keyof T]: TypedFormControl<T[R]>;
};

export type InitialTypedFormControlsFor<T> = {
  [R in keyof T]: [T[R] | undefined, never[]?];
};

export type FormErrorsFor<TFormData, TEmpty> = {
  [R in keyof TFormData]: string | WellKnownErrorCode | TEmpty;
};

export type FormActionData<TFormData> = {
  error?: string | undefined;
  fieldErrors?: Partial<FormErrorsFor<TFormData, undefined>>;
};

export function getApiError(error: unknown): string | undefined {
  if (
    typeof error === "object" &&
    // eslint-disable-next-line
    (error as any).innerError
  ) {
    const err = error as BreezeQueuedSaveFailedError;

    // Http request errors are handled in add-authorization-header-interceptor.ts
    if (!err.innerError.httpResponse) {
      return parseQueuedSaveFailedError(err);
    }
  } else if (error instanceof HttpErrorResponse) {
    if (typeof error.error === "string") {
      return error.error;
    }

    if (error.status === 0) {
      return "Unable to reach the API server";
    }

    if (error.status === 401) {
      return "Access Denied";
    }

    if (error.status === 500) {
      if (error instanceof HttpErrorResponse) {
        const details = error.error as unknown | undefined;
        if (
          details &&
          typeof details === "object" &&
          "message" in details &&
          typeof details.message === "string"
        ) {
          return details.message;
        }
      }
    }
  }

  // eslint-disable-next-line
  if ("message" in (error as any)) {
    // eslint-disable-next-line
    return (error as any).message;
  }

  console.error(error);
  return undefined;
}

export function getApiFieldErrors<TFormData>(error: unknown) {
  const ngFormErrors: Record<string, WellKnownErrorCode | string | undefined> =
    {};
  if (
    typeof error === "object" &&
    // eslint-disable-next-line
    (error as any).innerError
  ) {
    const err = error as BreezeQueuedSaveFailedError;

    // Http request errors are handled in add-authorization-header-interceptor.ts
    if (err.innerError.entityErrors.length > 0) {
      err.innerError.entityErrors.forEach((entityError: EntityError) => {
        const field = entityError.propertyName;
        const message = entityError.errorMessage;
        ngFormErrors[field] = message;
      });
    }
  }

  return ngFormErrors;
}

export function buildActionData<TFormData>(
  actionData: FormActionData<TFormData> | undefined,
  error: unknown,
) {
  const fieldErrors = getApiFieldErrors(error);

  let apiErr: string | undefined;
  if (fieldErrors.null) {
    apiErr = fieldErrors.null;
    delete fieldErrors.null;
  }

  const hasFieldErrors = Object.keys(fieldErrors).length > 0;

  const formError = fieldErrors.formLevelValidation ?? apiErr;
  return {
    ...actionData,
    fieldErrors: fieldErrors,
    error: formError
      ? formError
      : hasFieldErrors
      ? undefined
      : getApiError(error) ?? "Something went wrong",
  };
}

export type FormErrors<TFormData, TEmpty = undefined> = Partial<
  Record<keyof TFormData, string | WellKnownErrorCode | TEmpty>
>;
export type FormErrorsResponse<TFormData, TEmpty = undefined> = [
  boolean,
  FormErrors<TFormData, TEmpty>,
];
export type NgFormValidateFn<TFormData> = (
  formData: Partial<TFormData>,
) => FormErrorsResponse<TFormData, null | undefined>;
export function getNgFormErrors<TFormData>(
  formGroup: TypedFormGroup<TFormData>,
  validate?: NgFormValidateFn<TFormData>,
  silent: boolean = false,
): FormErrorsResponse<TFormData, undefined> {
  let preValidation: FormErrors<TFormData, undefined> = {};
  const ignoredErrorFields: string[] = [];
  if (typeof validate === "function") {
    const formData = getFormData(formGroup.typedControls);
    const [_, preErrors] = validate(formData);

    // Ignore errors for fields that have been pre-validated as OK via the "null" value
    for (const [key, val] of Object.entries(preErrors)) {
      if (val === null) {
        ignoredErrorFields.push(key);
      } else if (val === undefined) {
        // ignore
      } else {
        // eslint-disable-next-line
        (preValidation as any)[key as any] = val;
      }
    }
  }

  const ngFormErrors: Record<string, WellKnownErrorCode | undefined> = {};

  //Global required string type field validation (validate trimmed values)
  const controls = formGroup.typedControls;
  for (const name in controls) {
    const value = controls[name].value;
    if (!!value && typeof value === "string") {
      if (isFormFieldRequired(controls[name]) && !value.trim()) {
        controls[name].setErrors({
          required: true,
        });
      }
    }
  }

  const invalidControlNames = getInvalidControlNames(formGroup.controls).filter(
    (x) => !ignoredErrorFields.includes(x),
  );
  if (invalidControlNames.length > 0) {
    if (!silent) {
      console.error("Invalid form fields", invalidControlNames);
    }

    const controls = formGroup.typedControls;
    for (const name in controls) {
      if (!invalidControlNames.includes(name)) {
        continue;
      }

      if (controls[name].invalid) {
        let controlErrors = controls[name].errors;

        // Handle required
        if (controlErrors && "required" in controlErrors) {
          ngFormErrors[name] = "required";
        }

        // TODO: handle other type of ng form errors, in conjunction with format-error-label.pipe
      }
    }

    return [
      true,
      {
        ...ngFormErrors,
        ...preValidation,
      },
    ];
  }

  return [Object.keys(preValidation).length > 0, preValidation];
}

export async function handleStandardValidationErrors<TFormData>(
  formGroup: TypedFormGroup<TFormData>,
  silent: boolean = false,
  customValidator: NgFormValidateFn<TFormData> = skipCustomFormValidation,
  summaryErrorMessage = "There are validation errors",
) {
  const [hasFormErrors, formErrors] = getNgFormErrors(
    formGroup,
    customValidator,
    silent,
  );
  if (hasFormErrors) {
    formGroup.actionData = {
      ...formGroup.actionData,
      error: summaryErrorMessage,
      fieldErrors: formErrors,
    };

    return true;
  }

  clearValidationErrors(formGroup);
  return false;
}

export function skipCustomFormValidation<TFormData>(
  formData: Partial<TFormData>,
): FormErrorsResponse<TFormData, null | undefined> {
  const errors: FormErrors<TFormData, null | undefined> = {};

  return [Object.keys(errors).length > 0, errors];
}

export function clearValidationErrors<TFormData>(
  formGroup: TypedFormGroup<TFormData>,
) {
  formGroup.actionData = {
    ...formGroup.actionData,
    error: undefined,
    fieldErrors: {},
  };
}

export type TypedSimpleChange<T> = Omit<
  SimpleChange,
  "previousValue" | "currentValue"
> & {
  previousValue: T;
  currentValue: T;
};

export type TypedSimpleChanges<T> = {
  [P in keyof T]?: TypedSimpleChange<T[P]>;
};

export interface TypedFormGroup<T> extends FormGroup {
  typedControls: TypedFormControlsFor<T>;

  typedPatchValue: (
    value: Partial<T>,
    options?: {
      onlySelf?: boolean;
      emitEvent?: boolean;
    },
  ) => void;

  actionData?: FormActionData<T>;
  transitionState?: TransitionState;
}

export function buildForm<T>(
  formBuilder: FormBuilder,
  initialValue: InitialTypedFormControlsFor<T>,
) {
  const formGroup: TypedFormGroup<T> = formBuilder.group<
    InitialTypedFormControlsFor<T>
  >(initialValue) as unknown as TypedFormGroup<T>;
  formGroup.typedControls =
    formGroup.controls as unknown as TypedFormControlsFor<T>;
  formGroup.typedPatchValue = (value, options) => {
    formGroup.markAsTouched();
    return formGroup.patchValue(value, options);
  };
  formGroup.transitionState = "idle";
  return formGroup;
}

function isFormFieldRequired<T>(abstractControl: TypedFormControl<T>): boolean {
  if (abstractControl.validator) {
    const validator = abstractControl.validator({} as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
  }

  return false;
}

function getInvalidControlNames(
  controls: Record<string, { invalid: boolean }>,
) {
  const invalidControlNames = [];
  for (const name in controls) {
    if (controls[name]?.invalid) {
      invalidControlNames.push(name);
    }
  }

  return invalidControlNames;
}

export function getFormData<T>(controls: TypedFormControlsFor<T>): Partial<T> {
  const pairs = Object.entries(controls);
  const formData = pairs.reduce(
    (prev, [key, val]) => {
      prev[key] = (val as TypedFormControl<unknown>).value;
      return prev;
    },
    {} as Record<string, unknown | undefined>,
  );
  return formData as unknown as T;
}

export type HTTPQuery<T> = {
  [key in keyof T]?:
    | string
    | number
    | null
    | boolean
    | (string | number | null | boolean)[]
    | HTTPQuery<Record<string, unknown>>
    | undefined;
};
export function querystring<T>(
  params: HTTPQuery<T>,
  prefix: string = "",
): string {
  return Object.keys(params)
    .map((key) => {
      const fullKey = prefix + (prefix.length ? `[${key}]` : key);
      // eslint-disable-next-line
      const value = (params as any)[key];
      if (value === undefined || value === null || value === "") {
        return "";
      }
      if (value instanceof Array) {
        if (value.length === 0) {
          return "";
        }

        const multiValue = value
          .map((singleValue) => encodeURIComponent(String(singleValue)))
          .join(`&${encodeURIComponent(fullKey)}=`);
        return `${encodeURIComponent(fullKey)}=${multiValue}`;
      }
      if (value instanceof Date) {
        return `${encodeURIComponent(fullKey)}=${encodeURIComponent(
          value.toISOString(),
        )}`;
      }
      if (value instanceof Object) {
        return querystring(
          value as HTTPQuery<Record<string, unknown>>,
          fullKey,
        );
      }
      return `${encodeURIComponent(fullKey)}=${encodeURIComponent(
        String(value),
      )}`;
    })
    .filter((part) => part.length > 0)
    .join("&");
}

export type FilterFormFields<TFormData> = {
  [x in keyof TFormData]?: TFormData[x] extends string
    ? "string" | "string[]"
    : TFormData[x] extends string[]
    ? "string" | "string[]"
    : TFormData[x] extends number
    ? "number" | "number[]"
    : TFormData[x] extends number[]
    ? "number" | "number[]"
    : TFormData[x] extends boolean
    ? "boolean" | "boolean[]"
    : TFormData[x] extends boolean[]
    ? "boolean" | "boolean[]"
    : TFormData[x] extends Date
    ? "date" | "date[]"
    : TFormData[x] extends Date[]
    ? "date" | "date[]"
    : never;
};

export function syncFromRouteParams<
  TFormData,
  TKeys extends Extract<keyof TFormData, string>,
>(
  context: { route: ActivatedRoute; destroyRef: DestroyRef },
  formGroup: TypedFormGroup<TFormData>,
  shape: FilterFormFields<TFormData>,
  defaultValues?: Partial<TFormData>,
) {
  for (const [paramName, type] of Object.entries(shape) as [
    TKeys,
    SupportedParamType,
  ][]) {
    syncFromRouteParam(context, formGroup, paramName, type);
  }

  if (defaultValues) {
    const formData = getFormData(formGroup.typedControls);
    for (const [key, defaultVal] of Object.entries(defaultValues) as [
      TKeys,
      unknown,
    ][]) {
      const existingVal = formData[key];
      if (
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        existingVal === undefined ||
        (Array.isArray(existingVal) && existingVal.length === 0)
      ) {
        formGroup.patchValue({
          [key]: defaultVal,
        });
      }
    }
  }
}

export function syncFromRouteParam<
  TFormData,
  TKey extends Extract<keyof TFormData, string>,
>(
  context: { route: ActivatedRoute; destroyRef: DestroyRef },
  formGroup: TypedFormGroup<TFormData>,
  paramName: TKey,
  type: SupportedParamType,
): void {
  context.route.queryParams
    .pipe(takeUntilDestroyed(context.destroyRef))
    .subscribe((params) => {
      const rawVal = params[paramName] as unknown | undefined;
      const newVal = tryParseValue(type, rawVal);
      formGroup.patchValue({
        [paramName]: ([false, null, undefined] as unknown[]).includes(newVal)
          ? undefined
          : newVal,
      });
    });
}

export function syncToQueryStrings<
  TFormData,
  TKeys extends Extract<keyof TFormData, string>,
>(formGroup: TypedFormGroup<TFormData>, shape: FilterFormFields<TFormData>) {
  const formData = getFormData(formGroup.typedControls);
  const obj: HTTPQuery<TFormData> = {};
  for (const [key, type] of Object.entries(shape) as [
    TKeys,
    SupportedParamType,
  ][]) {
    const val = formData[key];
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (val === undefined || val === null || val === "") {
      // do nothing
    } else if (typeof val === "number" || typeof val === "string") {
      obj[key] = val;
    } else if (typeof val === "boolean") {
      // Exclude false from the URL
      if (val !== false) {
        obj[key] = val;
      }
    } else if (val instanceof Date) {
      obj[key] = dayjs(val).format(FORMAT_DAYJS_API);
    } else if (Array.isArray(val)) {
      const normalizedArray: (string | number | null | boolean)[] = val
        .map((x) => {
          if (typeof x === "number" || typeof x === "string") {
            return x;
          }

          if (typeof x === "boolean") {
            // Exclude false from the URL
            if (x === false) {
              return undefined;
            }

            return x;
          }

          if (x instanceof Date) {
            return dayjs(x).format(FORMAT_DAYJS_API);
          }

          return undefined;
        })
        .filter(Boolean);
      obj[key] = normalizedArray;
    } else {
      obj[key] = `${val}`;
    }
  }

  const qs = querystring(obj);

  const filterQueryString = qs ? "?" + qs : "";

  window.history.replaceState(
    undefined,
    "",
    window.location.pathname + filterQueryString,
  );
}

export function paginate<T>(
  allItems: T[],
  pageSize: number,
  pageNumber: number,
) {
  const page = allItems.slice(
    (pageNumber - 1) * pageSize,
    pageNumber * pageSize,
  );
  return page;
}

export function getDeletedItems<
  TEntity,
  TFormGroup extends object,
  TKeyType extends string | number = string | number,
>(
  originalItems: TEntity[],
  newForms: (TFormGroup | TypedFormGroup<TFormGroup>)[],
  entityIdField: KeysOfType<TEntity, TKeyType>,
  formIdField: KeysOfType<TFormGroup, TKeyType>,
) {
  const originalIds = originalItems.map((x) => x[entityIdField]) as TKeyType[];
  const seenIds = newForms
    .map((x) =>
      "typedControls" in x
        ? x.typedControls[formIdField].value
        : x[formIdField],
    )
    .filter(Boolean) as TKeyType[];
  const unseenIds = originalIds.filter((x) => !seenIds.includes(x));
  const deletedItems =
    unseenIds.length > 0
      ? originalItems.filter((x) =>
          unseenIds.includes(x[entityIdField] as TKeyType),
        )
      : [];

  return deletedItems;
}

export function getDeletedItemsFromIdsArray<
  TEntity extends MetaEntity,
  TKeyType extends string | number = string | number,
>(
  originalItems: TEntity[],
  newItemIds: (number | string)[],
  entityIdField: KeysOfType<TEntity, TKeyType>,
) {
  const originalIds = originalItems.map((x) => x[entityIdField]) as TKeyType[];
  const seenIds = newItemIds;
  const unseenIds = originalIds.filter((x) => !seenIds.includes(x));
  const deletedItems =
    unseenIds.length > 0
      ? originalItems.filter((x) =>
          unseenIds.includes(x[entityIdField] as TKeyType),
        )
      : [];

  return deletedItems;
}

export function getFormHasValues<TFormData>(
  formGroup: TypedFormGroup<TFormData>,
  // eslint-disable-next-line
  excludeFields: (keyof TFormData)[] = [
    "pageNumber",
    "pageSize",
    "orderBy",
    // eslint-disable-next-line
  ] as any,
) {
  const formData = getFormData(formGroup.typedControls);

  for (const field of excludeFields) {
    delete formData[field];
  }

  const formValues = Object.values(formData);
  const hasFormValues = formValues.some((x) => {
    if (x === undefined || x === null || x === "") {
      return false;
    }

    if (Array.isArray(x) && x.length === 0) {
      return false;
    }

    return true;
  });

  return hasFormValues;
}
