import { NgIf } from "@angular/common";
import { Component, Input } from "@angular/core";

import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { IconDefinition as RawIconDefinition } from "@fortawesome/fontawesome-svg-core";

type FabricIconName = "Info";
export type IconDefinition = RawIconDefinition | FabricIconName;

@Pipe({
  standalone: true,
  name: "faIcon",
})
export class FaIconPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(def: IconDefinition) {
    if (typeof def === "string") {
      return "";
    }

    const { icon } = def;
    const [width, height, ligatures, unicode, svgPathData] = icon;

    let content = "";
    if (Array.isArray(svgPathData)) {
      content = `<g>${svgPathData.map((x) => `<path d="${x}" />`)}</g>`;
    } else {
      content = `<path fill="currentColor" d="${svgPathData}" />`;
    }

    return this.sanitizer.bypassSecurityTrustHtml(content);
  }
}

@Component({
  standalone: true,
  selector: "standard-icon",
  template: `
    <svg
      *ngIf="_icon"
      [class]="
        'svg-inline--fa ' + (fixedWidth ? 'fa-fw ' : '') + (styleClass || '')
      "
      [attr.viewBox]="'0 0 ' + _icon.icon[0] + ' ' + _icon.icon[1]"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      [innerHTML]="_icon | faIcon"
    ></svg>
    <i
      *ngIf="_msIcon"
      [class]="'ms-Icon ms-Icon--' + _msIcon + ' ' + (styleClass || '')"
      aria-hidden="true"
    ></i>
  `,
  styles: [
    `
      :host {
        display: inline-flex;
        align-self: stretch;
        align-items: center;
      }

      svg:not(:root).svg-inline--fa,
      svg:not(:host).svg-inline--fa {
        overflow: visible;
        box-sizing: content-box;
      }

      .svg-inline--fa.fa-fw {
        width: var(--fa-fw-width, 1.25em);
      }

      .fa-fw {
        text-align: center;
        width: 1.25em;
      }

      .svg-inline--fa {
        display: var(--fa-display, inline-block);
        height: 1em;
        overflow: visible;
        vertical-align: -0.125em;
      }
    `,
  ],
  imports: [NgIf, FaIconPipe],
})
export class StandardIconComponent {
  @Input() styleClass: string | undefined;
  @Input() fixedWidth: boolean | undefined = true;

  protected _icon: RawIconDefinition | undefined;
  protected _msIcon: FabricIconName | undefined;
  @Input("icon") set icon(value: IconDefinition | undefined) {
    if (typeof value !== "string") {
      this._icon = value;
      this._msIcon = undefined;
    } else {
      this._msIcon = value;
      this._icon = undefined;
    }
  }
  get icon() {
    return this._msIcon ?? this._icon;
  }
}
