import { DestroyRef, Injectable, inject } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { BehaviorSubject } from "rxjs";
import { UnitOfWorkService } from "src/app/services/unit-of-work/unit-of-work.service";
import { baseSecurableName } from "src/config";
import { StandardLogService } from "src/standard/logging/logging";
import invariant from "tiny-invariant";
import Securable from "../ts/security/Securable";
import baseGetSecurableName from "../ts/security/getSecurableName";
import isActionAllowed from "../ts/security/isActionAllowed";
import trimSpecific from "../ts/utils/trimSpecific";
import { SecurableAction, SecurableName } from "./securable.directive";
import {
  EmployeeMarketGeography,
  VwEngagementTeamMember,
} from "../../model/model";
import { Router } from "@angular/router";

export interface UserSummary {
  employeeId: string;
  firstName: string;
  lastName: string;
  fullName: string;
  systemAdmin: boolean;
  canBeLoggedIn: boolean;
  canImpersonateUsers: boolean;
  isImpersonatedUser: boolean;
  isIndividual: boolean | undefined;
  isLeadership: boolean | undefined;
  securityRoleIds: number[];
  createSecurables: boolean;
  employeeMarketGeographies: EmployeeMarketGeography[];
  employeeEngagementTeamMembers: VwEngagementTeamMember[];
}

export const userSummary$ = new BehaviorSubject<
  [boolean, UserSummary | null | undefined]
>([false, undefined]);

interface SecurableNameProps {
  securable?: SecurableName;
  securablePart?: SecurableName;
  href?: string;
}

export function getSecurableName(path: string): string;
// eslint-disable-next-line no-redeclare
export function getSecurableName(path: SecurableNameProps): string | undefined;
// eslint-disable-next-line no-redeclare
export function getSecurableName(
  path: string | SecurableNameProps,
): string | undefined {
  if (!path) {
    return "";
  }

  if (typeof path === "string") {
    if (path.startsWith(window.location.origin)) {
      path = path.replace(window.location.origin, "");
    }

    if (path.startsWith(baseSecurableName)) {
      return baseGetSecurableName({ securableName: _getSecurableName(path) });
    }

    return baseGetSecurableName({
      securableName: _getSecurableName([baseSecurableName, path]),
    });
  }

  const obj = path;
  let securableName: string | undefined;
  if (obj.securable) {
    securableName = obj.securable;
  } else if (obj.securablePart) {
    securableName = getSecurableName(location.href + "." + obj.securablePart);
  } else if (obj.href) {
    securableName = getSecurableName(obj.href);
  }

  if (securableName) {
    return baseGetSecurableName({ securableName });
  }

  return undefined;
}

function _getSecurableName(path: string[] | string): string {
  if (typeof path === "string") {
    path = path
      .replace(/\/null/g, "/x")
      .replace(/(\d+)/g, "x")
      .replace(/(\s+)/g, "-")
      .replace(/(\/)/g, ".")
      .replace(/\.edit$/, "")
      .replace(/\.edit\.x$/, "")
      .toLowerCase();
    path = trimSpecific(path, ".");
    return path;
  }

  if (Array.isArray(path) && path.length > 0) {
    return path.map(_getSecurableName).join(".");
  }

  return "";
}

@Injectable({
  providedIn: "root",
})
export class SecurityService {
  constructor(
    private uow: UnitOfWorkService,
    private msalService: MsalService,
    private log: StandardLogService,
    private router: Router,
  ) {}

  private existingSecurables: string[] | undefined;
  private securableData: Securable[] | undefined;

  public get userSummary() {
    return userSummary$.value;
  }
  public set userSummary(newVal: [boolean, UserSummary | null | undefined]) {
    userSummary$.next(newVal);
  }

  public get isAdmin() {
    return this.userSummary[1]?.systemAdmin ?? false;
  }

  get isConfiguringSecurityEnabled() {
    const [isReady, userSummary] = this.userSummary;
    if (!isReady) {
      return false;
    }

    if (!userSummary) {
      return false;
    }

    return userSummary.createSecurables;
  }

  public validatePermissionsAndRedirectToHomePage = async (entity: any) => {
    if (!entity) {
      await this.router.navigate(["/"]);
      return;
    }
  };

  public async hydrateUserSummary(): Promise<UserSummary | null> {
    const [isReady, existingUserSummary] = this.userSummary;
    if (isReady) {
      invariant(existingUserSummary !== undefined);
      return existingUserSummary;
    }

    //
    // Look up security details
    //
    const [currentUserSummary, existingSecurables] = await Promise.all([
      this.uow.bridge.getCurrentUserSummary({ take: 1 }).catch((error) => {
        this.log.logException(error);
        console.error("Failed to get user information");
        this.userSummary = [true, null];

        throw new Error("Failed to get user information");
      }),
      this.uow.bridge.getSecuritySecurables(),
    ]);
    invariant(currentUserSummary);
    const { canBeLoggedIn = false } = currentUserSummary;

    if (currentUserSummary.createSecurables) {
      invariant(existingSecurables);
      this.existingSecurables = existingSecurables.map((x) => x.name);
    }

    // Convert user details to user summary obj
    const {
      employeeId,
      firstName = "",
      lastName = "",
      isIndividual,
      isLeadership,
      securityRoleNames = [],
      securityRoleIds = [],
      createSecurables = false,
      canImpersonateUsers = false,
      isImpersonatedUser = false,
      employeeMarketGeographies = [],
      employeeEngagementTeamMembers = [],
    } = currentUserSummary;
    const systemAdmin = securityRoleNames.includes("Admin");
    invariant(employeeId, "employeeId not valid");
    const userSummary: UserSummary = {
      employeeId: employeeId,
      firstName: firstName,
      lastName: lastName,
      fullName: firstName + " " + lastName,
      systemAdmin: systemAdmin,
      canBeLoggedIn: canBeLoggedIn,
      canImpersonateUsers: canImpersonateUsers,
      isImpersonatedUser: isImpersonatedUser,
      isIndividual: isIndividual ?? false,
      isLeadership: isLeadership ?? false,
      securityRoleIds: securityRoleIds,
      createSecurables: createSecurables,
      employeeMarketGeographies: employeeMarketGeographies,
      employeeEngagementTeamMembers: employeeEngagementTeamMembers,
    };
    this.userSummary = [this.userSummary[0], userSummary];

    invariant(userSummary.employeeId, "Failed to get user information");
    invariant(
      Array.isArray(userSummary.securityRoleIds),
      "Failed to get user roles",
    );
    invariant(
      userSummary.securityRoleIds.length > 0,
      "User has no roles, security cannot work",
    );

    return userSummary;
  }

  public async logout() {
    await this.msalService.instance.handleRedirectPromise();
    return this.msalService.logoutRedirect().toPromise();
  }

  async refreshCachedSecurableData() {
    this.securableData = undefined;
    await this.hydrateCachedSecurableData();
  }

  async hydrateCachedSecurableData(): Promise<boolean | undefined> {
    const [isReady] = this.userSummary;
    if (isReady && this.securableData !== undefined) {
      return true;
    }

    const currentUserSecurityData = await this.uow.bridge
      .getSecurityAccessDetails(true)
      .catch((error) => {
        console.error("Failed to get user information");
        this.userSummary = [true, null];

        throw new Error("Failed to get user information");
      });

    invariant(currentUserSecurityData);
    const securableData = currentUserSecurityData;
    invariant(securableData, "Failed to get securable data");
    this.securableData = securableData
      .filter((x) => typeof x.isAllowed === "boolean")
      .map((x) => {
        invariant(x.securityRoleId);
        invariant(x.action);
        const securable: Securable = {
          SecurityRoleId: x.securityRoleId,
          SecurableName: x.securableName,
          Action: x.action,
          IsAllowed: x.isAllowed,
        };
        return securable;
      });

    this.userSummary = [true, this.userSummary[1]];
    return true;
  }

  isActionAllowedSync(
    securableName: string,
    action: SecurableAction,
    securityRoleIds: number[] | undefined = undefined,
    unknownValue: boolean | null = false,
  ) {
    const [isReady, userSummary] = this.userSummary;
    if (!isReady) {
      return false;
    }

    const canCurrentUserBeLoggedIn = userSummary?.canBeLoggedIn;
    if (!canCurrentUserBeLoggedIn) {
      return false;
    }

    securableName = getSecurableName(securableName);

    // Special-case security around user impersonation
    if (securableName.startsWith(getSecurableName("/admin/impersonation"))) {
      const canImpersonateUsers = userSummary.canImpersonateUsers;
      if (!canImpersonateUsers) {
        return false;
      }

      return true;
    }

    if (securableName === getSecurableName("access-denied")) {
      return true;
    }

    if (securityRoleIds === undefined) {
      securityRoleIds = userSummary.securityRoleIds;
    }

    if (!Array.isArray(securityRoleIds) || securityRoleIds.length === 0) {
      console.error(
        `No roles for this user, so ${securableName} is not allowed.`,
      );
      return false;
    }

    //
    // When in config mode, Look up securable, or create one if doesn't yet exist
    //
    if (this.isConfiguringSecurityEnabled) {
      const systemAdmin = userSummary.systemAdmin;
      if (systemAdmin) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.tryCreateSecurable(securableName);
      }
    }

    const isAllowed = isActionAllowed(
      securityRoleIds,
      securableName,
      action,
      this.securableData ?? [],
    );

    if (userSummary.systemAdmin) {
      return true;
    }

    const isUnknown = isAllowed === undefined;
    console.debug(
      `${
        isUnknown ? "UNKNOWN" : isAllowed ? "ALLOWED" : "NOT ALLOWED"
      } security for ${securableName} with Action ${action} under roles[${securityRoleIds}]`,
    );
    if (isUnknown) {
      return unknownValue;
    }

    return isAllowed;
  }

  private async tryCreateSecurable(securableName: string) {
    // Only create securables for local development
    if (window.__settings?.environment !== "Local") {
      return;
    }

    const [isReady, userSummary] = this.userSummary;
    invariant(isReady);
    if (!userSummary) {
      return;
    }

    if (!userSummary.createSecurables) {
      return;
    }

    invariant(this.existingSecurables);
    const allSecurables = this.existingSecurables;

    // Only try to create securables if we've got a big list of "all" in memory
    // otherwise, no-nop
    if (allSecurables.length > 0) {
      const existingSecurable = allSecurables.filter(
        (x) => x === securableName,
      )[0];
      if (!existingSecurable) {
        try {
          const newEntity = this.uow.bridge.SecuritySecurable.createEntity({
            name: securableName,
          });
          this.uow.bridge.SecuritySecurable.addEntity(newEntity);
          await this.uow.commit();

          this.existingSecurables.push(securableName);
        } catch (error: unknown) {
          this.log.logException(error);
          // ignore
        }
      }
    }
  }
}
