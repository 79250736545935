import dayjs from "dayjs";
import { convertToDate } from "src/standard/pipes/convert-to-date.pipe";

export type SingleParamType = "boolean" | "date" | "string" | "number";
export type ArrayParamType = `${SingleParamType}[]`;
export type SupportedParamType = SingleParamType | ArrayParamType;

export function isArrayParamType(
  type: SupportedParamType,
): type is ArrayParamType {
  return type.endsWith("[]");
}

const parseMap: Record<SingleParamType, (rawVal: unknown) => unknown> = {
  string: tryParseString,
  number: tryParseNumber,
  boolean: tryParseBoolean,
  date: tryParseDate,
};

export function tryParseValue(type: SupportedParamType, rawVal: unknown) {
  if (isArrayParamType(type)) {
    const typeWithoutTrailingBrackets = type.slice(0, -2) as SingleParamType;
    return tryParseToArray(parseMap[typeWithoutTrailingBrackets], rawVal);
  }

  const fn = parseMap[type];
  return fn(rawVal);
}

export function tryParseToArray<TType>(
  fn: (rawVal: unknown) => TType | undefined,
  rawVal: unknown,
): TType[] {
  let newVal: TType[] = [];
  if (rawVal === undefined || rawVal === null || rawVal === "") {
    return [];
  }

  if (Array.isArray(rawVal)) {
    newVal = [...rawVal.map((x) => fn(x))].filter(
      (x) => x !== undefined,
    ) as TType[];
  } else {
    const parsedVal = fn(rawVal);
    if (parsedVal !== undefined) {
      newVal = [parsedVal];
    }
  }

  return newVal;
}

export function tryParseString(rawVal: unknown): string | undefined {
  let newVal: string | undefined;
  if (rawVal === undefined || rawVal === null || rawVal === "") {
    return undefined;
  }

  if (Array.isArray(rawVal)) {
    if (rawVal.length === 0) {
      return undefined;
    }

    rawVal = tryParseString(rawVal[0]);
  }

  if (typeof rawVal === "string") {
    newVal = rawVal;
  } else {
    newVal = `${rawVal}`;
  }

  return newVal;
}

export function tryParseNumber(rawVal: unknown): number | undefined {
  let newVal: number | undefined;
  if (rawVal === undefined || rawVal === null || rawVal === "") {
    return undefined;
  }

  if (Array.isArray(rawVal)) {
    if (rawVal.length === 0) {
      return undefined;
    }

    rawVal = tryParseNumber(rawVal[0]);
  }

  if (typeof rawVal === "number") {
    newVal = rawVal;
  } else if (typeof rawVal === "string") {
    const asNumber = Number(rawVal);
    if (Number.isNaN(asNumber)) {
      return undefined;
    }

    newVal = asNumber;
  }

  return newVal;
}

export function tryParseBoolean(rawVal: unknown): boolean | undefined {
  let newVal: boolean | undefined;
  if (rawVal === undefined || rawVal === null || rawVal === "") {
    return undefined;
  }

  if (Array.isArray(rawVal)) {
    if (rawVal.length === 0) {
      return undefined;
    }

    rawVal = tryParseBoolean(rawVal[0]);
  }

  if (typeof rawVal === "boolean") {
    newVal = rawVal;
  } else if (typeof rawVal === "number") {
    if (rawVal === 1) {
      newVal = true;
    } else if (rawVal === 0) {
      newVal = false;
    }
  } else if (typeof rawVal === "string") {
    if (rawVal.toLowerCase() === "true" || rawVal === "1") {
      newVal = true;
    } else if (rawVal.toLowerCase() === "false" || rawVal === "0") {
      newVal = false;
    }
  }

  return newVal;
}

export function tryParseDate(rawVal: unknown): Date | undefined {
  let newVal: Date | undefined;
  if (rawVal === undefined || rawVal === null || rawVal === "") {
    return undefined;
  }

  if (Array.isArray(rawVal)) {
    if (rawVal.length === 0) {
      return undefined;
    }

    rawVal = tryParseDate(rawVal[0]);
  }

  if (
    typeof rawVal === "string" ||
    typeof rawVal === "number" ||
    rawVal instanceof Date
  ) {
    newVal = convertToDate(rawVal);
  }

  return newVal;
}
