export type TooltipPositionType = "top" | "bottom" | "left" | "right";

import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Inject,
  Input,
  NgZone,
  PLATFORM_ID,
  Renderer2,
} from "@angular/core";
import { PrimeNGConfig } from "primeng/api";
import { Tooltip } from "primeng/tooltip";

/**
 * Tooltip directive provides advisory information for a component.
 * @group Components
 */
@Directive({
  selector: "[standardTooltip]",
  standalone: true,
  host: {
    class: "p-element",
  },
})
export class StandardTooltip extends Tooltip {
  @Input("standardTooltip") text: string | undefined;

  constructor(
    @Inject(PLATFORM_ID) platformId: any,
    el: ElementRef,
    zone: NgZone,
    config: PrimeNGConfig,
    renderer: Renderer2,
    changeDetectorRef: ChangeDetectorRef,
  ) {
    super(platformId, el, zone, config, renderer, changeDetectorRef);
  }

  /**
   * Extending PrimeNg functionality to toggle tooltip on click instead of deactivating (hiding)
   * Allows tooltips to be clickable/viewable on mobile
   * @param e
   */
  onInputClick(e: Event) {
    if (this.active) {
      this.deactivate();
    } else {
      this.activate();
    }
  }
}
